import React, { useEffect, useState, useCallback } from 'react';
import Popup from '../Popup';
import Tips from '../../textual/Tips';
import Stack from '../../../box/container/Stack';
import TextInput from '../../form/Textinput';
import Button from '../../button/Button';
import SelectSearchable from '../../form/SelectSearchable';
import { get, put } from 'aws-amplify/api';
import { useUser } from '../../../../contexts/UserContext';
import { notifyError, notifySuccess } from '../../Toastify';
import Title from '../../textual/Title';

const EditUserPopup = ({ open, onClose, userData }) => {
    const { user } = useUser();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const getGroups = useCallback(async () => {
        try {
            const restOperation = get({
                apiName: 'usersApi',
                path: '/users/groups',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                },
            });
            const response = await restOperation.response;
            const groupData = await response.body.json();
            setGroups(groupData.map(group => ({ id: group.groupName, label: group.groupName })));
        } catch (e) {
            console.error('GET groups call failed: ', e);
        }
    }, [user.accessToken]);

    useEffect(() => {
        getGroups();

        if (userData) {
            setEmail(userData.email || '');
            const userGroup = userData.groups?.[0] || null;
            setSelectedGroup(
                userGroup ? { id: userGroup, label: userGroup } : null
            );

            if (userData.profile) {
                setName(userData.profile.name || '');
                setSurname(userData.profile.surname || '');
                setPhoneNumber(userData.profile.phone_number || '');
            }
        }
    }, [getGroups, userData]);

    const handleGroupSelection = (option) => {
        setSelectedGroup(option || null);
    };

    async function handleUpdateUser() {
        setIsLoading(true);

        const updateData = {
            username: userData.username,
            newEmail: email !== userData.email ? email : undefined,
            newGroups: selectedGroup ? [selectedGroup.id] : undefined,
            profile: {
                name,
                surname,
                phone_number: phoneNumber,
            },
        };

        try {
            if (!email) {
                notifyError('L\'email est obligatoire pour modifier un utilisateur.');
                setIsLoading(false);
                return;
            }

            const result = await put({
                apiName: 'usersApi',
                path: '/users/update',
                options: {
                    headers: {
                        Token: `user_token ${user.accessToken}`,
                    },
                    body: updateData,
                },
            });

            const response = await result.response;

            if (response.statusCode === 200) {
                notifySuccess('Utilisateur modifié avec succès.');
                setIsLoading(false);
                onClose();
            } else {
                notifyError('Erreur lors de la modification de l\'utilisateur.');
                setIsLoading(false);
                onClose();
            }
        } catch (error) {
            console.error('Erreur lors de la modification de l’utilisateur :', error);
            setIsLoading(false);
            notifyError('Erreur lors de la modification de l\'utilisateur.');
            onClose();
        }
    }

    return (
        <Popup open={open} onClose={onClose} title={`Modifier l'utilisateur ${userData.email}`}>
            <Stack direction={"column"} spacing={"15px"}>
                <Stack direction={"column"} spacing={"20px"}>
                    <Stack direction={"column"} spacing={"30px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type="email"
                                label="Email"
                                autoComplete="new-email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                variant={"blue"}
                            />
                        </Stack>
                        <Stack direction={"column"}>
                            <Title level={5}>Choisissez un groupe de permission :</Title>
                            <SelectSearchable
                                options={groups}
                                onSelect={handleGroupSelection}
                                defaultText="Sélectionnez un groupe"
                                value={selectedGroup ? selectedGroup.label : ''}
                                label="Groupes"
                            />

                            {selectedGroup && selectedGroup.label === 'Admin' && (
                                <Tips variant="warning">
                                    Les administrateurs ont des permissions sensibles comme ajouter/supprimer des utilisateurs ou des clients, assurez-vous de leur fiabilité !
                                </Tips>
                            )}
                            {selectedGroup && selectedGroup.label === 'Reltim' && (
                                <Tips variant="success">
                                    Le groupe Reltim peut lire toutes les données sur l'application sans effectuer d'action.
                                </Tips>
                            )}
                        </Stack>

                        {userData.profile && (
                            <Stack direction={"column"}>
                                <Title level={5}>Modifier le profil utilisateur :</Title>
                                <TextInput
                                    type="text"
                                    label="Prénom"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    variant={"blue"}
                                />
                                <TextInput
                                    type="text"
                                    label="Nom"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    variant={"blue"}
                                />
                                <TextInput
                                    type="text"
                                    label="Numéro de téléphone"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    variant={"blue"}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Button width="full-width" variant={"primary"} onClick={handleUpdateUser} loading={isLoading}>
                        Valider les modifications
                    </Button>
                </Stack>
            </Stack>
        </Popup>
    );
};

export default EditUserPopup;
