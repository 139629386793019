import React, { useEffect, useState, useMemo } from 'react';
import { useUser } from '../../contexts/UserContext';
import ClientsItem from '../ui/aside-section/ClientsItem';
import fetchS3File from '../../hooks/getdata/fetchs3File';
import LoadingSpinner from '../../states/LoadingSpinner';
import Accordion from '../ui/Accordion';
import { useSidebar } from '../../contexts/SideBarContext';
import { ROUTES } from '../../routes/routes';

function CustomerClients() {
  const { user } = useUser();
  const [clientLogos, setClientLogos] = useState({});
  const { isAccordionOpen, toggleAccordion } = useSidebar();

  const allClients = useMemo(() => user?.clients || [], [user?.clients]);

  useEffect(() => {
    const fetchLogos = async () => {
      const logos = {};
      for (const client of allClients) {
        if (client.logo) {
          try {
            const url = await fetchS3File(client.logo, client.logoIdentityId);
            logos[client.id] = url;
          } catch (error) {
            console.error('Error fetching client logo:', error);
          }
        }
      }
      setClientLogos(logos);
    };

    if (allClients.length > 0) {
      fetchLogos();
    }
  }, [allClients]);

  const handleAccordionToggle = (isOpen) => {
    toggleAccordion(isOpen);
  };

  return (
    <Accordion
      title={allClients.length === 1 ? "Votre Qualiopi" : "Vos Qualiopi"}
      defaultOpen={isAccordionOpen}
      chipValue={allClients.length}
      highlight={false}
      fontSize="sm"
      variant="compact"
      onToggle={handleAccordionToggle}
    >
      <ul className="menu">
        {allClients && allClients.length > 0 ? (
          <>
            {allClients.map((client) => (
              <ClientsItem
                key={client.id}
                text={client.name}
                logo={clientLogos[client.id] || '/illustration/svg/utilisateur.svg'}
                variant="classique"
                to={ROUTES.ADMIN_CLIENT_DETAILS(client.id)}
              />
            ))}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </ul>
    </Accordion>
  );
}

export default CustomerClients;
