export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      profileClientId
      __typename
    }
  }
`;


export const createEntityDocumentRelation = /* GraphQL */ `
  mutation CreateEntityDocumentRelation(
    $input: CreateEntityDocumentRelationInput!
    $condition: ModelEntityDocumentRelationConditionInput
  ) {
    createEntityDocumentRelation(input: $input, condition: $condition) {
      id
      entityId
      entityClass
      createdAt
      updatedAt
      documentsEntityRelationId
      entityDocumentRelationDocumentId
      __typename
    }
  }
`;
export const updateEntityDocumentRelation = /* GraphQL */ `
  mutation UpdateEntityDocumentRelation(
    $input: UpdateEntityDocumentRelationInput!
    $condition: ModelEntityDocumentRelationConditionInput
  ) {
    updateEntityDocumentRelation(input: $input, condition: $condition) {
      id
      entityId
      entityClass
      createdAt
      updatedAt
      documentsEntityRelationId
      entityDocumentRelationDocumentId
      __typename
    }
  }
`;

export const deleteEntityDocumentRelation = /* GraphQL */ `
  mutation DeleteEntityDocumentRelation(
    $input: DeleteEntityDocumentRelationInput!
    $condition: ModelEntityDocumentRelationConditionInput
  ) {
    deleteEntityDocumentRelation(input: $input, condition: $condition) {
      id
      entityId
      entityClass
      createdAt
      updatedAt
      documentsEntityRelationId
      entityDocumentRelationDocumentId
      __typename
    }
  }
`;