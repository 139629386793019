import React, { createContext, useContext, useState, useEffect } from 'react';
import { generateClient } from "aws-amplify/api";
import { createEntityDocumentRelation, deleteEntityDocumentRelation } from '../graphqlCustom/mutationsCustom';
import { listAuditTemplates, getAuditTemplate, getClients, listClients, getAudit, listEntityDocumentRelations } from '../graphqlCustom/queriesCustom';
import { confirm } from "../components/confirm/ConfirmGlobal";
import {
  listTasksTemplates,
  listAudits,
  listTasks,
  listDocuments,
} from "../graphql/queries";
import {
  createClients,
  deleteClients,
  updateClients,
  createAuditTemplate,
  deleteAuditTemplate,
  createTasksTemplate,
  deleteTasksTemplate,
  updateTasksTemplate,
  createAudit,
  updateAudit,
  deleteAudit,
  createTasks,
  updateTasks,
  deleteTasks,
  updateAuditTemplate,
  createDocuments,
  deleteDocuments,
} from "../graphql/mutations";
import { useUser } from './UserContext';
import { notifyError, notifySuccess } from "../components/ui/Toastify";

const client = generateClient();
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { isLoggedIn, user, isAdmin, isReltim } = useUser();
  const [allClientsCount, setAllClientsCount] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [audits, setAudits] = useState([]);
  const [pressed, setPressed] = useState(true);
  const [Tokens, setTokens] = useState([null]);
  const [indexToken, setIndexToken] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [boolNext, setBoolNext] = useState(false);
  const [boolPrev, setBoolPrev] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const fetchAllClientsforCount = async () => {
    try {
      const result = await client.graphql({
        query: listClients,
      });
      setAllClients(result.data.listClients.items);
      setAllClientsCount(result.data.listClients.items.length);
    } catch (error) {
      console.error('Error fetching all clients for count:', error);
    }
  };


  async function fetchTasks() {
    try {
      const taskData = await client.graphql({
        query: listTasks,
      });
      let items = taskData.data.listTasks.items;
      setTasks(items);
    } catch (error) {
      notifyError("Erreur lors de la récupération des données");
      console.log('error on fetching data', error);
    }
  }

  async function fetchAudits() {
    try {
      const auditData = await client.graphql({
        query: listAudits,
      });
      let items = auditData.data.listAudits.items;
      setAudits(items);
    } catch (error) {
      notifyError("Erreur lors de la récupération des données");
      console.log('error on fetching data', error);
    }
  }


  useEffect(() => {
    async function fetchData() {
      let useToken = Tokens[indexToken];

      if (boolNext) {
        useToken = Tokens[indexToken + 1];
        setIndexToken(prevIndex => prevIndex + 1);
        setBoolNext(false);
      }

      if (boolPrev) {
        if (indexToken === 0)
          useToken = null;
        else {
          useToken = Tokens[indexToken - 1];
          setIndexToken(prevIndex => prevIndex - 1);
        }
        setBoolPrev(false);
      }

      try {
        const clientData = await client.graphql({
          query: listClients,
          variables: {
            limit: itemsPerPage,
            nextToken: useToken
          }
        });

        const { nextToken: newNextToken } = clientData.data.listClients;
        let items = clientData.data.listClients.items;

        if (items.length === 0) {
          setIndexToken(0);
          const tempData = await client.graphql({
            query: listClients,
            variables: {
              limit: itemsPerPage,
              nextToken: null
            }
          });
          items = tempData.data.listClients.items;
        }

        if (!boolPrev) {
          if (indexToken === Tokens.length - 2 || indexToken === Tokens.length - 1) {
            setTokens(prevTokens => [...prevTokens, newNextToken]);
          } else {
            console.log("no new token", Tokens);
          }
        }

        setClients(items);
      } catch (error) {
        notifyError("Erreur lors de la récupération des données");
        console.log('error on fetching data', error);
      }
    }

    if (isLoggedIn && isAdmin || isReltim) {
      try {
        if (pressed || boolNext || boolPrev || refresh) {
          fetchData();
          setPressed(false);
        }
        fetchTasks();
        fetchAllClientsforCount();
        fetchAudits();
      } catch (error) {
        console.log('error on fetching data', error);
      }
    }
  }, [isLoggedIn, pressed, boolNext, boolPrev, Tokens, indexToken, itemsPerPage, setIndexToken, setBoolNext, setBoolPrev, setTokens, refresh]);


  async function create(email, name, description) {
    console.log(email)
    try {
      const newClient = await client.graphql({
        query: createClients,
        variables: {
          input: {
            "email": email,
            "name": name,
            "description": description,
          }
        }
      });
      notifySuccess("Client ajouté");
      return newClient;
    } catch (error) {
      notifyError("Erreur lors de la création du client");
      console.error("Erreur lors de la création du client :", error);
    }
  }

  async function updateClient(id) {
    try {
      await client.graphql({
        query: getClients,
        variables: { id: id }
      });

      const updatedClient = await client.graphql({
        query: updateClients,
        variables: {
          input: {
            id: id,
            "email": formData.email.toLowerCase(),
            "name": formData.name.toLowerCase(),
            "description": formData.description,
          }
        }
      });

      notifySuccess("Client mis à jour avec succès");
      console.log("Client mis à jour :", updatedClient);
    } catch (error) {
      notifyError("Erreur lors de la mise à jour du client");
      console.error("Erreur lors de la mise à jour du client :", error);
    }
  }


  async function deleteClient(id) {
    try {

      const clientResult = await client.graphql({
        query: getClients,
        variables: { id: id }
      });
      const cliententity = clientResult.data.getClients;
      const audits = cliententity.audits.items;

      console.log(cliententity)
      console.log(audits)

      for (const audit of audits) {
        await deleteAnAudit(audit.id);
      }

      const deletedClient = await client.graphql({
        query: deleteClients,
        variables: {
          input: {
            id: id
          }
        }
      });
      notifySuccess("Client supprimé avec succès");
      console.log("Client supprimé :", deletedClient);
    } catch (error) {
      console.error("Erreur lors de la suppression du client :", error);
      notifyError("Erreur lors de la suppression du client");
    }
  }

  async function createAnAuditTemplate(name, type, cycle) {
    try {
      const newTemplate = await client.graphql({
        query: createAuditTemplate,
        variables: {
          input: {
            name: name,
            type: type,
            cycleFrequency: cycle
          }
        }
      });
      console.log("Nouveau template audit :", newTemplate);
    } catch (error) {
      console.error("Erreur lors de la création du template audit :", error);
    }
  }

  async function deleteAnAuditTemplate(id) {
    try {
      const userConfirmed = await confirm({
        title: "Voulez-vous vraiment supprimer ce template d'audit ?",
        content: "Ce template d'audit sera supprimé définitivement et vous ne pourrez plus l'utiliser.",
        variant: "danger"
      });

      if (userConfirmed) {
        const auditResult = await client.graphql({
          query: getAuditTemplate,
          variables: { id: id }
        });
        const auditTemplate = auditResult.data.getAuditTemplate;
        const tasksTemplates = auditTemplate.tasksTemplates.items;

        tasksTemplates.forEach(async (tasksTemplates) => {
          await client.graphql({
            query: deleteTasksTemplate,
            variables: {
              input: { id: tasksTemplates.id }
            }
          });
        });

        const deletedTemplate = await client.graphql({
          query: deleteAuditTemplate,
          variables: {
            input: {
              id: id
            }
          }
        });
        console.log("Template audit supprimé :", deletedTemplate);
        notifySuccess('Template d\'audit supprimé avec succès');
      } else {
        console.log("Suppression annulée par l'utilisateur.");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du template d'audit :", error);
      notifyError("Le template d'audit n'a pas été supprimé");
    }
  }


  async function createAnTaskTemplate(name, daysBeforeTargetDay, auditTemplateId, description) {
    try {
      const newTemplate = await client.graphql({
        query: createTasksTemplate,
        variables: {
          input: {
            auditTemplateTasksTemplatesId: auditTemplateId,
            name: name,
            description: description,
            daysBeforeTargetDay: daysBeforeTargetDay
          }
        }
      });
      console.log("Nouveau template de tâche :", newTemplate);
    } catch (error) {
      console.error("Erreur lors de la création du template de tâche :", error);
    }
  }

  async function deleteAnTaskTemplate(id) {
    try {
      const userConfirmed = await confirm({
        title: "Voulez-vous vraiment supprimer ce template de tâche ?",
        content: "Ce template de tâche sera supprimé définitivement et vous ne pourrez plus l'utiliser.",
        variant: "danger"
      });

      if (userConfirmed) {
        const deletedTemplate = await client.graphql({
          query: deleteTasksTemplate,
          variables: {
            input: {
              id: id
            }
          }
        });
        console.log("Template de tâche supprimé :", deletedTemplate);
        notifySuccess('Template de tâche supprimé avec succès');
      } else {
        console.log("Suppression annulée par l'utilisateur.");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du template de tâche :", error);
      notifyError("Le template de tâche n'a pas été supprimé");
    }
  }


  async function listAuditTemplate() {
    try {
      const templates = await client.graphql({
        query: listAuditTemplates
      });
      return templates.data.listAuditTemplates.items;
    } catch (error) {
      console.error("Erreur lors de la récupération des templates audit :", error);
    }
  }

  async function getAnAuditTemplate(id) {
    try {
      const template = await client.graphql({
        query: getAuditTemplate,
        variables: {
          id: id
        }
      });
      console.log(template.data.getAuditTemplate);
      return template.data.getAuditTemplate;
    } catch (error) {
      console.error("Erreur lors de la récupération du template audit :", error);
    }
  }

  async function editAnAuditTemplate(id, name, type, cycleFrequency) {
    try {
      await client.graphql({
        query: updateAuditTemplate,
        variables: {
          input: {
            id: id,
            name: name,
            type: type,
            cycleFrequency: cycleFrequency,
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  }

  async function listTaskTemplate(filter) {
    try {
      const templates = await client.graphql({
        query: listTasksTemplates,
        filter: {
          filter
        }
      });
      return templates.data.listTasksTemplates.items;
    } catch (error) {
      console.error("Erreur lors de la récupération des templates de tâche :", error);
    }
  }

  async function createAnAudit(clientId, name, status, targetDate, type) {
    console.log(clientId, name, status, targetDate, type);
    try {
      const newAudit = await client.graphql({
        query: createAudit,
        variables: {
          input: {
            clientsAuditsId: clientId,
            name: name,
            status: status,
            targetDate: targetDate,
            type: type
          }
        }
      });
      console.log("Nouvel audit :", newAudit);
      return newAudit;
    } catch (error) {
      console.error("Erreur lors de la création de l'audit :", error);
    }
  }

  async function editAnAudit(id, name, type, date) {
    try {
      await client.graphql({
        query: updateAudit,
        variables: {
          input: {
            id: id,
            name: name,
            type: type,
            targetDate: date,
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  }

  async function deleteAnAudit(id) {
    try {
      const auditResult = await client.graphql({
        query: getAudit,
        variables: { id: id }
      });
      const audit = auditResult.data.getAudit;
      const tasks = audit.tasks.items;

      tasks.forEach(async (task) => {
        await client.graphql({
          query: deleteTasks,
          variables: {
            input: { id: task.id }
          }
        });
      });

      await client.graphql({
        query: deleteAudit,
        variables: {
          input: { id: id }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de l'audit :", error);
      notifyError("L'audit n'a pas été supprimé");
    }
  }

  async function createATask(auditId, tasksTemplateId, name, date, description) {
    try {
      const newTask = await client.graphql({
        query: createTasks,
        variables: {
          input: {
            auditTasksId: auditId,
            tasksTasksTemplateIDId: tasksTemplateId,
            name: name,
            description: description,
            date: date,
            done: false,
            globalPartitionKey:"task",
            doneStatus:"false"
          }
        }
      });
      console.log("Nouvelle tâche :", newTask);
    } catch (error) {
      console.error("Erreur lors de la création de la tâche :", error);
    }
  }

  async function updateATask(id, bool) {
    try {
      await client.graphql({
        query: updateTasks,
        variables: {
          input: {
            id: id,
            done: bool,
            doneStatus: bool === true ? "true" : "false"
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  }


  async function editATask(id, name, date, description) {
    try {
      await client.graphql({
        query: updateTasks,
        variables: {
          input: {
            id: id,
            name: name,
            description: description,
            date: date,
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  }

  async function editATaskTemplate(id, name, daysBeforeTargetDay, description) {
    try {
      await client.graphql({
        query: updateTasksTemplate,
        variables: {
          input: {
            id: id,
            name: name,
            description: description,
            daysBeforeTargetDay: daysBeforeTargetDay,
          }
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  }

  async function deleteATask(id) {
    try {
      const userConfirmed = await confirm({
        title: "Voulez-vous vraiment supprimer cette tâche ?",
        content: "Cette tâche sera supprimée définitivement et vous ne pourrez plus intéragir avec.",
        variant: "danger"
      });

      if (userConfirmed) {
        const deletedTask = await client.graphql({
          query: deleteTasks,
          variables: {
            input: {
              id: id
            }
          }
        });
        console.log("Tâche supprimée :", deletedTask);
        notifySuccess('Tâche supprimée avec succès');
      } else {
        console.log("Suppression annulée par l'utilisateur.");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche :", error);
      notifyError("La tâche n'a pas été supprimée");
    }
  }

  async function createADocument(name, description, path, identityId) {
    try {
      const result = await client.graphql({
        query: createDocuments,
        variables: {
          input: {
            name: name,
            description: description,
            path: path,
            identityId: identityId
          }
        },
        authMode: 'lambda',
        authToken: `user_token ${user.accessToken}`
      });

      if (result.data?.createDocuments) {
        notifySuccess('Document créé avec succès');
        return result.data.createDocuments;
      }

      if (result.errors) {
        console.error("Erreurs lors de la création :", result.errors);
        notifyError("La création du document a échoué");
        return null;
      }

    } catch (error) {
      console.error("Erreur lors de la création du document :", error);
      notifyError("Erreur lors de la création du document");
      return null;
    }
  }

  async function listAllDocuments() {
    try {
      const documents = await client.graphql({
        query: listDocuments,
        authMode: 'lambda',
        authToken: `user_token ${user.accessToken}`,
      });
      return documents.data.listDocuments.items;
    } catch (error) {
      console.error("Erreur lors de la récupération des documents :", error);
    }
  }

  async function deleteADocument(id, path) {
    try {
      const userConfirmed = await confirm({
        title: "Voulez-vous vraiment supprimer ce document ?",
        content: "Ce document et ses relations avec les clients, audits ou tâches seront supprimés définitivement.",
        variant: "danger"
      });

      if (userConfirmed) {

        const relationsResponse = await client.graphql({
          query: listEntityDocumentRelations,
          variables: {
            entityDocumentRelationDocumentId: id
          },
          authMode: 'lambda',
          authToken: `user_token ${user.accessToken}`,
        });

        const relations = relationsResponse.data.listEntityDocumentRelations.items || [];

        for (const relation of relations) {
          await client.graphql({
            query: deleteEntityDocumentRelation,
            variables: {
              input: {
                id: relation.id
              }
            },
            authMode: 'lambda',
            authToken: `user_token ${user.accessToken}`,
          });
        }

        // Supprimer le document dans s3
        // await removeS3File(path);

        await client.graphql({
          query: deleteDocuments,
          variables: {
            input: {
              id: id
            }
          },
          authMode: 'lambda',
          authToken: `user_token ${user.accessToken}`,
        });
        notifySuccess('Document supprimé avec succès');
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du document :", error);
      notifyError("Le document n'a pas été supprimé");
    }
  }



  async function createADocumentEntityRelation(entityClass, entityId, documentsId) {
    try {
      const result = await client.graphql({
        query: createEntityDocumentRelation,
        variables: {
          input: {
            entityClass: entityClass,
            entityId: entityId,
            entityDocumentRelationDocumentId: documentsId,
          }
        },
        authMode: 'lambda',
        authToken: `user_token ${user.accessToken}`
      });

      if (result.data?.createEntityDocumentRelation) {
        return result.data.createEntityDocumentRelation;
      }

      if (result.errors) {
        console.error("Erreurs lors de l'association du document :", result.errors);
        notifyError("L'association' du document a échoué");
        return null;
      }

    } catch (error) {
      console.error("Erreur lors de l'association' du document :", error);
      notifyError("Erreur lors de l'association' du document");
      return null;
    }
  }

  async function listAllDocumentEntityRelations() {
    try {
      const documents = await client.graphql({
        query: listEntityDocumentRelations,
        authMode: 'lambda',
        authToken: `user_token ${user.accessToken}`,
      });
      return documents.data.listDocuments.items;
    } catch (error) {
      console.error("Erreur lors de la récupération des documents associés:", error);
    }
  }

  async function deleteADocumentRelation(id) {
    try {
      const userConfirmed = await confirm({
        title: "Voulez-vous vraiment dissocier ce document ?",
        content: "Ce document ne sera plus associé à ce client, mais il ne sera pas supprimé de l'application.",
        variant: "danger"
      });
  
      if (userConfirmed) {
        await client.graphql({
          query: deleteEntityDocumentRelation,
          variables: {
            input: {
              id: id
            }
          },
          authMode: 'lambda',
          authToken: `user_token ${user.accessToken}`,
        });
        notifySuccess("Ce document n'est plus associé à ce client");
      }
  
      return userConfirmed;
    } catch (error) {
      console.error("Erreur lors de la dissociation du document :", error);
      notifyError("Le document n'a pas été dissocié");
      return false;
    }
  }


  return (
    <DataContext.Provider value={{
      client,
      clients,
      allClientsCount,
      allClients,
      audits,
      tasks,
      setPressed,
      create,
      setBoolNext,
      setBoolPrev,
      setTokens,
      indexToken,
      setIndexToken,
      Tokens,
      deleteClient,
      updateClient,
      setItemsPerPage,
      formData,
      setFormData,
      handleInputChange,
      refresh,
      setRefresh,
      createAnAuditTemplate,
      deleteAnAuditTemplate,
      editAnAuditTemplate,
      listAuditTemplate,
      getAnAuditTemplate,
      createAnTaskTemplate,
      deleteAnTaskTemplate,
      listTaskTemplate,
      editATaskTemplate,
      createAnAudit,
      editAnAudit,
      deleteAnAudit,
      createATask,
      updateATask,
      editATask,
      deleteATask,
      createADocument,
      listAllDocuments,
      deleteADocument,
      createADocumentEntityRelation,
      listAllDocumentEntityRelations,
      deleteADocumentRelation
    }}>
      {children}
    </DataContext.Provider>
  )
}

export const useData = () => {
  return useContext(DataContext);
}
