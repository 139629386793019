import React from 'react';
import styled, { css } from "styled-components";
import BottomBarItems from './BottomBarItems';
import Stack from '../../box/container/Stack';
import { CiSettings, CiGrid41, CiViewList } from 'react-icons/ci';
import { PiUser, PiUsersThin } from "react-icons/pi";
import { ROUTES } from '../../../routes/routes';

const baseStyles = css`
  z-index: 10;
  bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  box-shadow: 0px 4px 11.5px #0000001a;
  position: sticky;
  gap: 34px;
  justify-content: space-between;
  border-radius: 100px;
`;

const transparentStyles = css`
  ${baseStyles};
  background: linear-gradient(94deg, rgba(255, 255, 255, 0.10) 5.01%, rgba(255, 255, 255, 0.50) 94.81%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const coloredStyles = css`
  ${baseStyles};
  background: var(--secondary-bg-color);
`;

const BottomBarContainer = styled.div`
  ${({ bottombarvariant }) => bottombarvariant ? transparentStyles : coloredStyles};

  @media (min-width: 1000px) {
    display: none;
  }
`;

const BottomBar = ({ bottombarvariant }) => {
    return (
        <BottomBarContainer bottombarvariant={bottombarvariant}>
            <Stack padding="13px 23px" justify="space-between" width="100%">
                <BottomBarItems icon={CiGrid41} to={ROUTES.ADMIN_HOME} iconColorVariant={bottombarvariant ? 'transparent' : 'default'} />
                <BottomBarItems icon={PiUsersThin} to={ROUTES.ADMIN_CLIENTS} iconColorVariant={bottombarvariant ? 'transparent' : 'default'} />
                <BottomBarItems icon={CiViewList} to={ROUTES.ADMIN_AUDIT_TEMPLATES} iconColorVariant={bottombarvariant ? 'transparent' : 'default'} />
                <BottomBarItems icon={CiSettings} to={ROUTES.SETTINGS} iconColorVariant={bottombarvariant ? 'transparent' : 'default'} />
                <BottomBarItems icon={PiUser} to={ROUTES.PROFILE} iconColorVariant={bottombarvariant ? 'transparent' : 'default'} />
            </Stack>
        </BottomBarContainer>
    );
};

export default BottomBar;
