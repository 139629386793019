import React, { useState } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import Pastille from '../../textual/Pastille';
import { CiCircleCheck, CiLocationOn, CiMail, CiWarning } from 'react-icons/ci';
import Stack from '../../../box/container/Stack';
import Title from '../../textual/Title';
import { PiUserCircleDashedThin, PiUserCircleGearThin } from 'react-icons/pi';

const ViewUserPopup = ({ open, onClose, user, profile_picture }) => {
    const [error, setError] = useState(null);

    if (!user) {
        setError("Aucun utilisateur")
    }

    return (
        <Popup open={open} onClose={onClose} title={`Utilisateur: ${user?.username}`}>
            {error ? (
                <Text variant="error">{error}</Text>
            ) : user ? (
                <Stack direction={"column"} spacing={"20px"}>
                    <Stack direction={"column"}>
                        <Title level={3}>Informations cognito</Title>
                        <img
                            src={profile_picture || "/illustration/svg/utilisateur.svg"}
                            alt={user?.username}
                            style={{ width: '50px', height: '50px', borderRadius: '50px', objectFit: 'cover' }}
                        />
                        <Stack direction={"column"} spacing={"5px"}>
                            <Text>Date de création : {new Date(user.createdAt).toLocaleDateString()}</Text>
                            <Pastille variant={"success"}><CiMail />{user.email}</Pastille>
                            <>
                                {user.status === 'CONFIRMED' && (
                                    <Pastille variant="success">
                                        <CiCircleCheck /> Confirmé
                                    </Pastille>
                                )}
                                {user.status === 'FORCE_CHANGE_PASSWORD' && (
                                    <Pastille variant="warning">
                                        <CiWarning /> Doit changer son mot de passe
                                    </Pastille>
                                )}
                                {user.status !== 'CONFIRMED' && user.status !== 'FORCE_CHANGE_PASSWORD' && (
                                    <Pastille variant="warning">
                                        <CiWarning /> {user.status || 'Non renseigné'}
                                    </Pastille>
                                )}
                            </>
                            {user.groups && user.groups.length > 0 ? (
                                <>
                                    {user.groups.includes('Admin') && (
                                        <Pastille variant="danger"><PiUserCircleGearThin />Administrateur</Pastille>
                                    )}
                                    {!user.groups.includes('Admin') && user.groups.includes('Reltim') && (
                                        <Pastille variant="success"><PiUserCircleDashedThin />Reltim</Pastille>
                                    )}
                                </>
                            ) : (
                                <Pastille variant="warning"><CiWarning />Aucun groupe</Pastille>
                            )}
                        </Stack>
                    </Stack>
                    <Stack direction={"column"}>
                        <Title level={3}>Profil dynamoDB</Title>
                        {user.profile && user.profile !== null ? (
                            <Stack direction={"column"} spacing={"5px"}>
                                <Text>
                                    Date de naissance : {user.profile.birthdate ? new Date(user.profile.birthdate).toLocaleDateString() : 'Non renseignée'}
                                </Text>
                                <Text>
                                    {user.profile.name && user.profile.surname
                                        ? `${user.profile.name} ${user.profile.surname}`
                                        : 'Nom et prénom non renseignés'}
                                </Text>
                                <Text>
                                    {user.profile.phone_number ? user.profile.phone_number : 'Numéro de téléphone non renseigné'}
                                </Text>
                                <Text>
                                    {user.profile.website ? user.profile.website : 'Site web non renseigné'}
                                </Text>
                                <Pastille variant={"success"}>
                                    {user.profile.city ? <><CiLocationOn />{user.profile.city}</> : 'Ville non renseignée'}
                                </Pastille>
                                <Text>
                                    Description : {user.profile.bio ? user.profile.bio : 'Description non renseignée'}
                                </Text>
                            </Stack>
                        ) : (
                            <Pastille variant="warning">Aucun profil enregistré en DynamoDB</Pastille>
                        )}

                    </Stack>
                </Stack>
            ) : (
                <Text>Aucun utilisateur trouvé.</Text>
            )}
        </Popup>
    );
};

export default ViewUserPopup;
