import MenuItem from '../ui/aside-section/HeaderItems';
import { PiUsersThreeThin } from "react-icons/pi";
import Accordion from '../ui/Accordion';
import { ROUTES } from '../../routes/routes';


function AdminMenu() {
    return (
        <Accordion title="Administrateur" defaultOpen={false} highlight={false} fontSize='sm' variant='compact'>
            <ul className="menu">
                <MenuItem text="Utilisateurs" icon={PiUsersThreeThin } variant="classique" to={ROUTES.ADMIN_USERS} />
            </ul>
        </Accordion>
    );
}

export default AdminMenu;