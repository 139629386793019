export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      profile_picture_identity_id
      city
      website
      birthdate
      clients {
        id
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listAuditTemplates = /* GraphQL */ `
  query ListAuditTemplates(
    $filter: ModelAuditTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tasksTemplates {
        items {
          id
          name
          description
          daysBeforeTargetDay
          auditTemplateTasksTemplatesId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      id
      description
      type
      updatedAt
      cycleFrequency
      createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getAuditTemplate = /* GraphQL */ `
  query GetAuditTemplate($id: ID!) {
    getAuditTemplate(id: $id) {
      createdAt
      cycleFrequency
      description
      id
      name
      tasksTemplates {
        items {
          auditTemplateTasksTemplatesId
          createdAt
          daysBeforeTargetDay
          description
          id
          name
          updatedAt
        }
        nextToken
      }
      type
      updatedAt
    }
  }
`;

export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      auditTemplateID {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      name
      type
      status
      targetDate
      realDate
      tasks {
        items {
          createdAt
          date
          description
          done
          id
          name
          reminderEmailSentAt
          reminderEmailTemplateID {
            body
            createdAt
            id
            subject
            updatedAt
          }
          sendReminderEmailDaysBeforeDate
          tasksReminderEmailTemplateIDId
          tasksTasksTemplateIDId
          updatedAt
          auditTasksId
        }
      }
      createdAt
      updatedAt
      clientsAuditsId
      auditAuditTemplateIDId
      __typename
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo
        logoIdentityId
        email
        name
        description
        audits {
        items {
          auditAuditTemplateIDId
          clientsAuditsId
          createdAt
          id
          name
          realDate
          status
          targetDate
          tasks {
            items {
              createdAt
              date
              description
              done
              id
              name
              reminderEmailSentAt
              reminderEmailTemplateID {
                body
                createdAt
                id
                subject
                updatedAt
              }
              sendReminderEmailDaysBeforeDate
              tasksReminderEmailTemplateIDId
              tasksTasksTemplateIDId
              updatedAt
              auditTasksId
            }
          }
          type
          updatedAt
        }
      }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const tasksByDate = /* GraphQL */ `
  query TasksByDate(
    $date: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        date
        done
        sendReminderEmailDaysBeforeDate
        reminderEmailSentAt
        createdAt
        updatedAt
        auditTasksId
        tasksTasksTemplateIDId
        tasksReminderEmailTemplateIDId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getClients = /* GraphQL */ `
  query GetClients($id: ID!) {
    getClients(id: $id) {
      id
      logo
      logoIdentityId
      email
      name
      description
      audits {
        items {
          auditAuditTemplateIDId
          clientsAuditsId
          createdAt
          id
          name
          realDate
          status
          targetDate
          tasks {
            items {
              createdAt
              date
              description
              done
              id
              name
              reminderEmailSentAt
              reminderEmailTemplateID {
                body
                createdAt
                id
                subject
                updatedAt
              }
              sendReminderEmailDaysBeforeDate
              tasksReminderEmailTemplateIDId
              tasksTasksTemplateIDId
              updatedAt
              auditTasksId
            }
          }
          type
          updatedAt
        }
      }
      audits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ClientByName = /* GraphQL */ `
  query ClientByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ClientByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEntityDocumentRelations = /* GraphQL */ `
  query ListEntityDocumentRelations(
    $filter: ModelEntityDocumentRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityDocumentRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        entityClass
        createdAt
        updatedAt
        documentsEntityRelationId
        entityDocumentRelationDocumentId
        __typename
        document {
          id
          path
          identityId
          name
          description
          createdAt
          updatedAt
          __typename
          }
      }
      nextToken
      __typename
    }
  }
`;