import React, { useEffect, useState } from 'react';
import Hero from '../../../components/box/section/Hero';
import Title from '../../../components/ui/textual/Title';
import Stack from '../../../components/box/container/Stack';
import SettingNavigation from '../../../components/ui/SettingNavigation';
import Text from '../../../components/ui/textual/Text';
import { useUser } from '../../../contexts/UserContext';
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { ROUTES } from '../../../routes/routes';

function Setting() {
    const { user, isLoggedIn } = useUser();
    const [themeLabel, setThemeLabel] = useState('');

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        switch (theme) {
            case 'system':
                setThemeLabel('Thème système');
                break;
            case 'light':
                setThemeLabel('Thème clair');
                break;
            case 'dark':
                setThemeLabel('Thème sombre');
                break;
            default:
                setThemeLabel('Thème non défini');
                break;
        }
    }, []);

    return (
        <Hero>
            <Stack separator>
                <Title level={1}>
                    Paramètres
                </Title>
            </Stack>
            <Stack direction={"column"}>
                <Text>
                    Paramètres généraux
                </Text>
                <SettingNavigation
                    title={"Compte"}
                    content={isLoggedIn ? (
                        <Stack align={"center"}>
                            <FaCheckCircle color="var(--success-color)" />
                            <Text>Vous êtes bien connecté {user.profile.name}</Text>
                        </Stack>
                    ) : (
                        <Stack align={"center"}>
                            <FaTimes color="var(--error-color)" />
                            <Text>Il y'a un problème</Text>
                        </Stack>
                    )}
                    to={ROUTES.SETTINGS_ACCOUNT}
                />
                <SettingNavigation
                    title={"Apparence"}
                    content={themeLabel}
                    to={ROUTES.SETTINGS_APPEARANCE}
                />
                <SettingNavigation
                    title={"Authentification"}
                    content={isLoggedIn ? `${user.profile.email}` : 'Vous êtes mal connecté'}
                    to={ROUTES.SETTINGS_AUTHENTICATION}
                />
            </Stack>
        </Hero>
    );
}

export default Setting;
