import React from 'react';
import Main from '../components/box/main/Main';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import '../assets/style/style.css';
import { ROUTES } from '../routes/routes';

const LogoutLayout = ({ children }) => {
    const { isLoggedIn, isReltim, isAdmin } = useUser();

    const getRedirectRoute = () => {
        if (isAdmin || isReltim) return ROUTES.ADMIN_HOME;
        return ROUTES.CUSTOMER_HOME;
    };

    return (
        <>
            {!isLoggedIn ? (
                <Main variant="wnotsidebar">
                    {children}
                </Main>
            ) : (
                <Navigate to={getRedirectRoute()} replace />
            )}
        </>
    );
}

export default LogoutLayout;
