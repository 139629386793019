import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../states/Loading';
import { ROUTES } from '../../routes/routes';

function CompleteProfileRoutes({ children }) {
    const { isLoggedIn, profileCompleted, isAdmin, isReltim } = useUser();

    const getRedirectRoute = () => {
        if (isAdmin || isReltim) return ROUTES.ADMIN_HOME;
        return ROUTES.CUSTOMER_HOME;
    };

    if (!isLoggedIn) {
        return <Navigate to={ROUTES.LOGIN} replace />;
    }

    if (isLoggedIn && profileCompleted === null) {
        return <Loading />;
    }

    if (isLoggedIn && profileCompleted === true) {
        return <Navigate to={getRedirectRoute()} replace />;
    }

    if (isLoggedIn && profileCompleted === false) {
        return children;
    }
}

export default CompleteProfileRoutes;