import Logout from '../../hooks/login-gestion/Logout';
import { ROUTES } from '../../routes/routes';
import MenuItem from '../ui/aside-section/HeaderItems';
import { CiSettings } from 'react-icons/ci';


function SecondaryMenu() {
    return (
        <ul className="menu">
            <MenuItem text="Paramètres" icon={CiSettings} variant="classique" to={ROUTES.SETTINGS} />
            <Logout />
        </ul>
    );
}

export default SecondaryMenu;