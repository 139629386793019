import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Title from '../textual/Title';
import Stack from '../../box/container/Stack';
import Section from '../../box/section/Section';
import Text from '../textual/Text';
import FormError from '../form/FormError';
import Pastille from '../textual/Pastille';
import TextInput from '../form/Textinput';
import { generateClient } from 'aws-amplify/api';
import { listClients } from '../../../graphqlCustom/queriesCustom';
import InvisibleLink from '../button/InvisibleLink';
import fetchS3File from '../../../hooks/getdata/fetchs3File';
import { ROUTES } from '../../../routes/routes';

const client = generateClient();

const OverlayWrapper = styled.div`
  position: fixed;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  transform: ${props => props.$showOverlay ? 'translateY(0%)' : 'translateY(-100%)'};
  visibility: ${props => props.$showOverlay ? 'visible' : 'hidden'};
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  z-index: 1000;
  padding: 15px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: var(--paragraph);
  font-size: 20px;
  cursor: pointer;
`;

const ResultItem = styled.li`
  margin-bottom: 10px;
  list-style: none;
  padding: 10px 14px;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background: var(--secondary-bg-color);
  }
`;

const SearchResult = styled.ul`
  margin-bottom: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid var(--grey-color);
`;

function SearchOverlay({ showOverlay, onClose }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [logoUrls, setLogoUrls] = useState([]);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeout);
    };
  }, [typingTimeout]);

  useEffect(() => {
    if (showOverlay) {
      setSearchQuery('');
    }
  }, [showOverlay]);

  const handleSearchChange = (event) => {
    const name = event.target.value;
    setSearchQuery(name);

    clearTimeout(typingTimeout);
    setLoading(false);
    setSearching(true);

    if (name.trim().length > 0) {
      setTypingTimeout(setTimeout(() => {
        setLoading(true);
        fetchResults(name.trim().toLowerCase());
        setSearching(false);
      }, 1000));
    } else {
      setSearchResults([]);
      setSearching(false);
    }
  };

  const fetchResults = async (name) => {
    const filter = {
      filter: {
        name: {
          contains: name
        }
      },
      limit: 10
    };

    try {
      const clientsResult = await client.graphql({
        query: listClients,
        variables: filter
      });

      const clients = clientsResult.data.listClients.items;

      const logoPromises = clients
        .filter(client => client.logo)
        .map(client => fetchS3File(client.logo, client.logoIdentityId));

      const logoUrls = await Promise.all(logoPromises);
      const logoMap = {};
      clients.forEach((client, index) => {
        if (client.logo) {
          logoMap[client.id] = logoUrls.shift();
        }
      });
      setLogoUrls(logoMap);
      setSearchResults(clients);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des résultats de recherche :', error.message);
      setLoading(false);
    }
  };

  if (!showOverlay) return null;

  return (
    <OverlayWrapper $showOverlay={showOverlay}>
      <Section>
        <CloseButton onClick={onClose}>Fermer</CloseButton>
        <Stack direction="column">
          <Title level={3}>Recherche de clients</Title>
          <TextInput
            type="text"
            label="Rechercher un client"
            value={searchQuery}
            onChange={handleSearchChange}
            autoFocus
          />
          {loading && <Text>Chargement...</Text>}
          {!loading && searchResults.length === 0 && !searching && searchQuery.length > 0 && (
            <FormError variant="error">Aucun résultat trouvé pour "{searchQuery}"</FormError>
          )}
          {searchResults.length > 0 && (
            <SearchResult>
              <Pastille variant="success">
                {searchResults.length} résultat{searchResults.length <= 1 ? '' : 's'} :
              </Pastille>
              {searchResults.map((clientSearch, index) => {
                return (
                  <ResultItem key={clientSearch.id ?? index}>
                    <InvisibleLink onClick={onClose} to={ROUTES.ADMIN_CLIENT_DETAILS(clientSearch.id)}>
                      <Stack align="center" spacing="10px">
                        <img src={logoUrls[clientSearch.id] ? (logoUrls[clientSearch.id]) : ("/illustration/svg/utilisateur.svg")} alt={`${clientSearch.name} logo`} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                        <Stack direction="column" spacing="0px" width={"100%"}>
                          <Stack justify="space-between">
                            <Stack align="center" spacing="6px">
                              <Title level={3} textTransform={"capitalize"}>{clientSearch.name}</Title>
                            </Stack>
                          </Stack>
                          <Text size="sm">{clientSearch.description}</Text>
                        </Stack>
                      </Stack>
                    </InvisibleLink>
                  </ResultItem>
                );
              })}
            </SearchResult>
          )}
        </Stack>
      </Section>
    </OverlayWrapper>
  );
}

export default SearchOverlay;
