import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { useData } from '../../../../contexts/DataContext';
import BackgroundImageContainer from "../../../../components/box/container/BackgroundImageContainer";
import Title from "../../../../components/ui/textual/Title";
import Text from "../../../../components/ui/textual/Text";
import Section from "../../../../components/box/section/Section";
import Loading from "../../../../states/Loading";
import Bento from "../../../../components/box/bento/Bento";
import Column from "../../../../components/box/container/Column";
import Pastille from "../../../../components/ui/textual/Pastille";
import { CiEdit, CiExport, CiMail, CiTrash } from "react-icons/ci";
import Container from "../../../../components/box/container/Container";
import Stack from '../../../../components/box/container/Stack';
import IconButton from '../../../../components/ui/button/IconButton';
import { useUser } from '../../../../contexts/UserContext';
import { IoMdAdd } from "react-icons/io";
import AnimationComponent from '../../../../components/animation/Animation';
import Empty from '../../../../components/animation/storageAnimation/empty.json';
import Tips from '../../../../components/ui/textual/Tips';
import fetchS3File from '../../../../hooks/getdata/fetchs3File';
import NotFound from '../../../../states/NotFound';
import Span from '../../../../components/ui/textual/Span';
import Table from "../../../../components/ui/table/Table";
import Th from "../../../../components/ui/table/Th";
import Td from "../../../../components/ui/table/Td";
import Checkbox from "../../../../components/ui/checkbox/CheckboxItem";
import Link from '../../../../components/ui/textual/Link';
import { dateConverter } from '../../../../tools/dateConverter';
import { confirm } from "../../../../components/confirm/ConfirmGlobal";
import { getClients } from '../../../../graphqlCustom/queriesCustom';
import {
  notifySuccess,
  notifyError,
} from '../../../../components/ui/Toastify';
import { generateClient } from "aws-amplify/api";
import SmallCircleProgress from '../../../../components/ui/progress-bar/SmallCircleProgress';
import EditAuditPopup from '../../../../components/ui/popup/allPopups/EditAuditPopup';
import { usePopup } from '../../../../contexts/PopupContext';
import ExportsAuditsPopup from '../../../../components/ui/popup/allPopups/ExportsAuditsPopup';
import { listEntityDocumentRelations } from '../../../../graphqlCustom/queriesCustom';
import AddEntityDocumentRelationPopup from '../../../../components/ui/popup/allPopups/AddEntityDocumentRelationPopup';
import ViewDocumentPopup from '../../../../components/ui/popup/allPopups/ViewDocumentPopup';
import { PiPaperclipLight } from 'react-icons/pi';
import ChipButton from '../../../../components/ui/button/ChipButton';
import { ROUTES } from '../../../../routes/routes';

const client = generateClient();

export default function ClientDetails() {
  const { popups, openPopup, closePopup } = usePopup();
  const { setFormData, refresh, deleteAnAudit, setPressed, setRefresh, deleteADocumentRelation } = useData();
  const { isAdmin, profilePictureURL, user } = useUser();
  const [clientDetail, setClientDetail] = useState(null);
  const [audits, setAudits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [auditChecked, setAuditChecked] = useState("notactive");
  const [clientDocuments, setClientDocuments] = useState([]);
  const { id } = useParams();





  const calculateGlobalProgress = () => {
    const totalAudits = audits.length;

    if (totalAudits === 0) return 0;

    let totalTasks = 0;
    let totalDoneTasks = 0;

    audits.forEach(audit => {
      const auditTasks = audit.tasks?.items || [];
      totalTasks += auditTasks.length;
      totalDoneTasks += auditTasks.filter(task => task.done).length;
    });

    if (totalTasks === 0) return 0;

    const globalProgress = (totalDoneTasks / totalTasks) * 100;
    return globalProgress;
  };



  async function getClient(id) {
    try {
      const clientTarget = await client.graphql({
        query: getClients,
        variables: { id: id }
      });
      return clientTarget.data.getClients;
    } catch (error) {
      console.error("Erreur lors de la récupération du client :", error);
      return null;
    }
  }

  useEffect(() => {
    async function fetchClientData() {
      setLoading(true);
      setLogo(null);
      try {
        const clientData = await getClient(id);
        if (clientData) {
          setFormData(clientData);
          setClientDetail(clientData);
          if (clientData.logo) {
            const logoUrl = await fetchS3File(clientData.logo, clientData.logoIdentityId);
            setLogo(logoUrl);
          }
          setAudits(clientData.audits.items);
        } else {
          setClientDetail(null);
        }
      } catch (e) {
        console.error("Erreur lors de la récupération du client", e);
        setClientDetail(null);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchClientData();
      setSelectedCount(0);
      setAllChecked(false);
      setCheckedItems({});
      setAuditChecked('notactive');
    }
  }, [id, setFormData, refresh]);


  const handleChildCheckboxChange = (auditId) => {
    const updatedCheckedItems = { ...checkedItems, [auditId]: !checkedItems[auditId] };
    const count = Object.values(updatedCheckedItems).filter((value) => value).length;
    setSelectedCount(count);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(count === audits.length);
  };

  const handleParentCheckboxChange = () => {
    const updatedCheckedItems = {};
    if (!allChecked) {
      audits.forEach((audit) => {
        updatedCheckedItems[audit.id] = true;
      });
    }
    setSelectedCount(Object.keys(updatedCheckedItems).length);
    setCheckedItems(updatedCheckedItems);
    setAllChecked(!allChecked);
  };

  async function handleDeleteAudit(id) {
    if (await confirm({
      title: "Voulez-vous vraiment supprimer cet audit ?",
      content: "Cet audit sera supprimé définitivement et personne ne pourra y accéder",
      variant: "danger"
    })) {
      try {
        await deleteAnAudit(id);
        notifySuccess('Audit et tâches associées supprimés avec succès');
        setRefresh(!refresh);
        setPressed(true);
      } catch (error) {
        console.error("error on deleting client", error);
        notifyError("Erreur lors de la suppression du client");
      }
    }
  }

  const deleteCheckedAudits = async () => {
    const checkedAuditsIds = Object.keys(checkedItems).filter(auditId => checkedItems[auditId]);
    try {
      if (await confirm({
        title: "Voulez-vous vraiment supprimer ces audits ?",
        content: "Ces audits seront supprimées définitivement et personne ne pourra y accéder",
        variant: "danger"
      })) {
        await Promise.all(checkedAuditsIds.map(async auditId => {
          await deleteAnAudit(auditId);
        }));
        setRefresh(!refresh);
        notifySuccess(`${checkedAuditsIds.length} audits supprimés avec succès`);
        setCheckedItems({});
      }
    } catch (error) {
      console.error("Erreur lors de la suppression des tâches :", error);
      notifyError("Erreur lors de la suppression des tâches");
    }
  };

  useEffect(() => {
    if (selectedCount > 0) {
      setAuditChecked("active");
    } else {
      setAuditChecked("notactive");
    }
  }, [selectedCount]);

  const fetchClientDocuments = useCallback(async (clientId) => {
    try {
      const response = await client.graphql({
        query: listEntityDocumentRelations,
        variables: {
          filter: {
            entityId: { eq: clientId },
            entityClass: { eq: 'client' },
          },
        },
        authMode: 'lambda',
        authToken: `user_token ${user.accessToken}`,
      });
      return response.data.listEntityDocumentRelations.items.map(relation => ({
        document: relation.document,
        relationId: relation.id
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des documents :', error);
      return [];
    }
  }, [user.accessToken]);
  useEffect(() => {
    async function fetchDocuments() {
      if (clientDetail?.id) {
        const documents = await fetchClientDocuments(clientDetail.id);
        setClientDocuments(documents);
      }
    }

    fetchDocuments();
  }, [clientDetail, fetchClientDocuments]);


  if (!clientDetail && loading) {
    return (
      <Loading />
    );
  }

  if (!loading && !clientDetail) {
    return (
      <NotFound />
    );
  }

  const getSelectedAudits = () => {
    return audits.filter(audit => checkedItems[audit.id]);
  };

  async function deleteRelationDocument(id) {
    const isDeleted = await deleteADocumentRelation(id);
    if (isDeleted) {
      setRefresh(!refresh);
    }
  }



  if (clientDetail) return (
    <>
      <Section>
        <BackgroundImageContainer coverUrl="/background/cover_4.jpg">
          <div className="profil-container">
            <img
              src={logo || "/illustration/svg/utilisateur.svg"}
              className="user-picture"
              alt={clientDetail ? clientDetail.name : "avatar"}
            />
            <div className="user-info">
              <div>
                <Title textTransform={"capitalize"} level={3} variant="white">
                  {clientDetail.name}
                </Title>
                <Text variant="white">{clientDetail.description}</Text>
              </div>
            </div>
          </div>
        </BackgroundImageContainer>
      </Section>
      <Section>
        <Container direction="row" align="start" width="100%">
          <Column width="30%" gap="20px">
            <Bento highlight="highlight">
              <Title level={4}>
                Informations
              </Title>
              {clientDetail.email ? (
                <Pastille variant="success">
                  <CiMail />{clientDetail.email}
                </Pastille>
              ) : (
                <Text><Span textTransform={"capitalize"}>{clientDetail.name}</Span> n'a pas d'email'</Text>
              )}
              {clientDocuments.length > 0 ? (
                <Stack flexWrap={"wrap"} spacing={"20px"}>
                  {clientDocuments.map((relation) => (
                    <React.Fragment key={relation.document.id}>
                      <ViewDocumentPopup
                        open={popups[`view ${relation.document?.id}`]}
                        onClose={() => closePopup(`view ${relation.document?.id}`)}
                        document={relation.document}
                      />
                      <ChipButton
                        variant={"action"}
                        onClick={() => openPopup(`view ${relation.document.id}`)}
                        deleteOnClick={isAdmin ? () => deleteRelationDocument(relation.relationId) : undefined}
                      >
                        <PiPaperclipLight />
                        {relation.document.name}
                      </ChipButton>
                    </React.Fragment>
                  ))}
                </Stack>
              ) : (
                <Text>Aucun document associé à ce client</Text>
              )}
              <IconButton
                width="fit-content"
                variant="secondary-action"
                onClick={() => openPopup("associate_document")}
              >
                <IoMdAdd />
                Associer un document
              </IconButton>
            </Bento>
          </Column>
          <Column width="70%" gap="50px">
            <Bento highlight={"highlight"} overflow={"visible"}>
              <Stack direction={"column"}>
                <Stack separator align={"center"} spacing={"20px"}>
                  <img
                    src={profilePictureURL || "/illustration/svg/utilisateur.svg"}
                    className="user-picture"
                    alt="avatar"
                  />
                  <Stack direction={"column"}>
                    <Title level={5}>
                      Que voulez-vous faire ?
                    </Title>
                    <Text>
                      Voici les actions pour le client <Span textTransform={"capitalize"}>{clientDetail.name}</Span>
                    </Text>
                  </Stack>
                </Stack>
                {isAdmin ? (
                  <Stack>
                    <IconButton variant="action" to={ROUTES.ADMIN_CREATE_AUDIT(clientDetail.id)}>
                      <IoMdAdd /> Ajouter un audit
                    </IconButton>
                    <IconButton variant="secondary-action" to={ROUTES.ADMIN_EDIT_CLIENT(clientDetail.id)}>
                      <CiEdit /> Modifier les informations
                    </IconButton>
                  </Stack>
                ) :
                  <Tips variant={"warning"}>Vous n'avez pas les privilèges pour effectuer une action, contactez un administrateur.</Tips>}
              </Stack>
            </Bento>
          </Column>
        </Container>
      </Section >
      <Section margin={"50px 0px"}>
        <Stack direction={"column"} spacing={"10px"}>
          {audits.length > 0 ? (
            <>
              <Stack justify={"space-between"}>
                <Stack spacing="20px">
                  <Title level={1}>Audits de <Span textTransform={"capitalize"}>{clientDetail.name}</Span></Title>
                  <SmallCircleProgress progress={calculateGlobalProgress()} showDecimals={false} />
                </Stack>
                <Stack spacing={"10px"}>
                  {isAdmin && (
                    <IconButton
                      fontSize={"16px"}
                      type='submit'
                      enable={auditChecked}
                      width="fit-content"
                      variant="danger"
                      onClick={deleteCheckedAudits}
                    >
                      <CiTrash /> Supprimer ({selectedCount})
                    </IconButton>
                  )}
                  <IconButton
                    fontSize={"16px"}
                    enable={auditChecked}
                    width="fit-content"
                    variant="secondary-action"
                    onClick={() => openPopup("exports")}
                  >
                    <CiExport /> Exporter ({selectedCount})
                  </IconButton>
                  {isAdmin && (
                    <IconButton variant="action" to={ROUTES.ADMIN_CREATE_AUDIT(clientDetail.id)} fontSize={"16px"}><IoMdAdd />Ajouter un audit</IconButton>
                  )}
                </Stack>
              </Stack>
              <Table>
                <thead>
                  <tr>
                    <Th variant="basique">
                      <Checkbox
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleParentCheckboxChange}
                      />
                    </Th>
                    <Th variant="basique">Nom de l'audit</Th>
                    <Th variant="basique">Tâches</Th>
                    <Th variant="basique">Date limite</Th>
                    <Th variant="basique">Progression</Th>
                    {isAdmin && (
                      <Th variant="basique">Action</Th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {audits.map((audit) => {
                    const totalTasks = audit.tasks && audit.tasks.items ? audit.tasks.items.length : 0;
                    const doneTasks = audit.tasks && audit.tasks.items ? audit.tasks.items.filter(task => task.done).length : 0;
                    const donePercentage = totalTasks ? (doneTasks / totalTasks) * 100 : 0;

                    return (
                      <tr key={audit.id}>
                        <EditAuditPopup
                          open={popups[`edit ${audit?.id}`]}
                          onClose={() => closePopup(`edit ${audit?.id}`)}
                          auditId={audit?.id}
                        />
                        <Td>
                          <Checkbox
                            type="checkbox"
                            checked={checkedItems[audit.id] || false}
                            onChange={() => handleChildCheckboxChange(audit.id)}
                          />
                        </Td>
                        <Td>
                          <Stack align={"center"}>
                            <Link textTransform={"capitalize"} to={ROUTES.ADMIN_GET_AUDITS(clientDetail.id, audit.id)} cropped>
                              {audit.name}
                            </Link>
                          </Stack>
                        </Td>
                        <Td>
                          <Pastille variant={totalTasks > 1 ? "success" : totalTasks === 1 ? "warning" : "danger"}>
                            {totalTasks > 0 ? `${totalTasks} tâche${totalTasks > 1 ? "s" : ""}` : "Aucune tâche"}
                          </Pastille>
                        </Td>
                        <Td>
                          <Pastille variant={"primary"}>{`${dateConverter(audit.targetDate)}`}</Pastille>
                        </Td>
                        <Td>
                          <SmallCircleProgress progress={donePercentage.toFixed(2)} showDecimals={false} />
                        </Td>
                        {isAdmin && (
                          <Td>
                            <Stack>
                              <IconButton
                                wtext={"no"}
                                variant="secondary-action"
                                onClick={() => openPopup(`edit ${audit?.id}`)}
                              >
                                <CiEdit />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteAudit(audit.id)} wtext={"no"} variant={"danger"} aria-label="Supprimer">
                                <CiTrash />
                              </IconButton>
                            </Stack>
                          </Td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <Stack direction="column" align="center">
              <AnimationComponent animationData={Empty} width="150px" />
              <Text>
                <Span textTransform={"capitalize"}>{clientDetail.name}</Span> n'a aucun audit
              </Text>
              {isAdmin ? (
                <IconButton variant="action" to={ROUTES.ADMIN_CREATE_AUDIT(clientDetail.id)}>
                  <IoMdAdd /> Ajouter un audit
                </IconButton>
              ) : (
                <Tips variant={"warning"}>
                  Vous n'avez pas les droits pour ajouter un audit, contactez un admin
                </Tips>
              )}
            </Stack>
          )}
        </Stack>
      </Section>
      <ExportsAuditsPopup
        open={popups["exports"]}
        onClose={() => closePopup("exports")}
        audits={getSelectedAudits()}
        clientName={clientDetail.name}
        logo={logo}
      />
      <AddEntityDocumentRelationPopup
        open={popups["associate_document"]}
        onClose={() => closePopup("associate_document")}
        entityId={clientDetail.id}
        entityClass="client"
        entityName={clientDetail.name}
        clientId={clientDetail.id}
      />
    </>
  );
}
