import MenuItem from '../../ui/aside-section/HeaderItems';
import { CiGrid41 } from 'react-icons/ci';
import { PiUser } from "react-icons/pi";
import { ROUTES } from '../../../routes/routes';


function CustomerDefaultMenu() {
    return (
        <ul className="menu">
            <MenuItem text="Tableau de bord" icon={CiGrid41 } variant="classique" to={ROUTES.CUSTOMER_HOME} />
            <MenuItem text="Profil" icon={PiUser} variant="classique" to={ROUTES.PROFILE} />
        </ul>
    );
}

export default CustomerDefaultMenu;
