import React, { useEffect, useState } from 'react';
import Title from "../../../components/ui/textual/Title";
import AuditCardTemplate from "../../../components/AuditCardTemplate";
import IconButton from "../../../components/ui/button/IconButton";
import { useData } from '../../../contexts/DataContext';
import Stack from '../../../components/box/container/Stack';
import Hero from '../../../components/box/section/Hero';
import { IoMdAdd } from 'react-icons/io';
import { useUser } from '../../../contexts/UserContext';
import { usePopup } from '../../../contexts/PopupContext';
import AddAuditTemplatePopup from '../../../components/ui/popup/allPopups/AddTemplatePopup';

export default function ListAuditTemplates() {
  const { popups, openPopup, closePopup } = usePopup();
  const { listAuditTemplate, refresh } = useData();
  const { isAdmin } = useUser();
  const [auditTemplates, setAuditTemplates] = useState([]);

  useEffect(() => {
    listAuditTemplate().then((templates) => {
      setAuditTemplates(templates);
    });
  }, [refresh, listAuditTemplate]);

  return (
    <>
      <Hero spacing={"30px"}>
        <Stack justify={"space-between"} align={"center"}>
          <Title level={1}>Liste des templates d'audit ({auditTemplates.length})</Title>
          {isAdmin && (
            <IconButton variant="action" onClick={() => openPopup('popupAuditTemplate')}>
              Créer un nouveau template <IoMdAdd />
            </IconButton>
          )}
        </Stack>
        <Stack direction="column" width={"100%"} spacing={"20px"}>
          {auditTemplates.map((template, index) => (
            <AuditCardTemplate key={index} audit={template} />
          ))}
        </Stack>
      </Hero>
      <AddAuditTemplatePopup
        open={popups['popupAuditTemplate']}
        onClose={() => closePopup('popupAuditTemplate')}
      />
    </>
  );
}
