import React, { useEffect, useState } from 'react';
import TextInput from "./Textinput";
import { useNavigate } from 'react-router-dom';
import Button from "../button/Button";
import { notifyError, notifySuccess } from "../Toastify";
import { useData } from '../../../contexts/DataContext';
import Textarea from "../../../components/ui/form/Textarea";
import Stack from '../../box/container/Stack';
import Form from './Form';
import { ROUTES } from '../../../routes/routes';
import { listProfiles } from '../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import Checkbox from '../../../components/ui/checkbox/CheckboxItem.js';
import { createClientProfiles } from '../../../graphql/mutations.js';

const client = generateClient();

export default function CreateClientForm() {
  const { create } = useData();
  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  // Gérer les profils sélectionnés
  const handleProfileChange = (profileId) => {
    setSelectedProfiles((prev) =>
      prev.includes(profileId)
        ? prev.filter((id) => id !== profileId) // Décocher : retirer l'ID
        : [...prev, profileId] // Cocher : ajouter l'ID
    );
  };

  const handleSubmit = async () => {
    if (!email || !name) {
      notifyError("Veuillez remplir les champs obligatoires");
      return;
    }

    try {
      const newClient = await create(email.toLowerCase(), name.toLowerCase(), description);
      if (newClient && newClient.data && newClient.data.createClients) {
        const clientsId = newClient.data.createClients.id;

        console.log(selectedProfiles)

        await Promise.all(
          selectedProfiles.map((profileId) =>
            createClientProfile(clientsId, profileId)
          )
        );

        notifySuccess("Client et relations créés avec succès");
        navigate(ROUTES.ADMIN_CLIENTS);
      }
    } catch (error) {
      console.error("Erreur lors de la création du client ou des relations :", error);
    }
  };

  const createClientProfile = async (clientsId, profileId) => {
    try {
      await client.graphql({
        query: createClientProfiles,
        variables: {
          input: {
            clientsId,
            profileId,
          },
        },
      });
    } catch (error) {
      console.error(`Erreur lors de la création de la relation ClientProfile (client: ${clientsId}, profile: ${profileId}):`, error);
    }
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const profilesResult = await client.graphql({
          query: listProfiles,
          authMode: 'userPool',
        });

        const profilesData = profilesResult.data.listProfiles;
        const profiles = profilesData && profilesData.items ? profilesData.items : [];
        setProfiles(profiles);
      } catch (error) {
        console.error('Erreur lors de la récupération des profils :', error);
      }
    };

    fetchProfiles();
  }, []);

  return (
    <Form>
      <Stack direction="column">
        <TextInput
          type="email"
          label={"Email*"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          variant={"blue"}
        />
        <TextInput
          type="text"
          label={"Nom*"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant={"blue"}
        />
        <Textarea
          maxLength={250}
          label={"Description"}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant={"blue"}
        />
        {profiles.map((profile) => (
          <Checkbox
            key={profile.id}
            value={profile.id}
            label={`${profile.name} ${profile.surname}`}
            checked={selectedProfiles.includes(profile.id)}
            onChange={() => handleProfileChange(profile.id)}
          />
        ))}
        <Button onClick={handleSubmit} variant={"primary"} width="full-width">
          Créer un client
        </Button>
      </Stack>
    </Form>
  );
}
