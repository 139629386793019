import MenuItem from '../ui/aside-section/HeaderItems';
import { CiFileOn, CiBoxList } from 'react-icons/ci';
import { PiPaperclipLight, PiUsersThin } from "react-icons/pi";
import Accordion from '../ui/Accordion';
import { ROUTES } from '../../routes/routes';


function MainMenu() {
    return (
        <Accordion title="Espace de travail" defaultOpen={false} highlight={false} fontSize='sm' variant='compact'>
            <ul className="menu">
                <MenuItem text="Clients" icon={PiUsersThin} variant="classique" to={ROUTES.ADMIN_CLIENTS} />
                <MenuItem text="Tâches" icon={CiBoxList} variant="classique" to={ROUTES.ADMIN_TASKS} />
                <MenuItem text="Templates" icon={CiFileOn } variant="classique" to={ROUTES.ADMIN_AUDIT_TEMPLATES} />
                <MenuItem text="Documents" icon={PiPaperclipLight} variant="classique" to={ROUTES.ADMIN_DOCUMENTS} />
            </ul>
        </Accordion>
    );
}

export default MainMenu;