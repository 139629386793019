import React from 'react';
import Title from '../../../components/ui/textual/Title';
import Text from '../../../components/ui/textual/Text';
import CurrentDate from '../../../components/ui/dynamic/CurrentDate';
import Hero from '../../../components/box/section/Hero';
import Container from '../../../components/box/container/Container';
import HighLightContainer from '../../../components/box/container/HighLightContainer';
import Column from '../../../components/box/container/Column';
import { useUser } from '../../../contexts/UserContext';

function Home() {
  const { user } = useUser();
  
  return (
    <Hero spacing="30px">
      <Container variant="normal" direction="row" width="100%" justify="space-between">
        <Column width="65%">
          <Title level={3}>
            Tableau de bord
          </Title>
          <Text>
            <CurrentDate highlight="day" uppercase="true" variant="long" />
          </Text>
        </Column>
      </Container>
      <HighLightContainer variant="primary" direction="row" width="100%" justify="space-between"
        animationType={"fadeIn"}>
        <Column width="35%">
          <img className='image-container' src='/illustration/svg/objectif.svg'
            alt="illustration d'un personnage"></img>
        </Column>
        <Column width="65%" justify="center">
          {user && (
            <Title level={1} variant="blue">
              Bienvenue {user.profile.name}
            </Title>
          )}
          <Text>
            Passez une agréable journée
          </Text>
        </Column>
      </HighLightContainer>
    </Hero>
  );
}

export default Home;
