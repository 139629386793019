import React, { useEffect } from 'react';
import Logo from '../../illustration/Logo.js';
import MainMenu from '../../menu/MainMenu';
import SecondaryMenu from '../../menu/SecondaryMenu';
import styled from 'styled-components';
import * as Animate from '../../animation/AnimationGsap';
import HeaderSearch from './HeaderSearch.js';
import { PiMagnifyingGlass } from 'react-icons/pi';
import ClientsMenu from '../../menu/ClientsMenu.js';
import DefaultMenu from '../../menu/DefaultMenu.js';
import AdminMenu from '../../menu/AdminMenu.js';
import { useUser } from '../../../contexts/UserContext.js';
import CustomerClients from '../../menu/CustomerClients.js';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 11;
    display: ${({ $isopen }) => ($isopen ? 'block' : 'none')};

    @media (min-width: 1000px) {
        display: none;
    }
`;

const StyledAside = styled.aside`
    background: var(--bg-color);
    padding: 15px;
    width: 280px;
    border-right: 2px solid var(--grey-color);
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 300;

    @media (max-width: 1000px) {
        transform: ${({ $isopen }) => ($isopen === 'false' ? 'translateX(-100%)' : 'translateX(0)')};
        transition: 0.2s;
    }
`;

function Header({ isopen, setisOpen }) {
    const element = React.useRef();
    const isFirstRenderRef = React.useRef(true);
    const { isAdmin, user } = useUser();

    useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
            return;
        }

        const isResponsive = window.matchMedia("(max-width: 1000px)").matches;

        if (isopen === true) {
            if (isResponsive) {
                Animate.animateMoveFromLeftResponsive(element.current);
            } else {
                Animate.animateMoveFromLeft(element.current);
            }
        } else if (isopen === false) {
            if (isResponsive) {
                Animate.animateMoveToLeftResponsive(element.current);
            } else {
                Animate.animateMoveToLeft(element.current);
            }
        }
    }, [isopen]);

    return (
        <>
            <Overlay $isopen={isopen} onClick={() => setisOpen(false)} />
            <StyledAside className="banner" $isopen={isopen.toString()} ref={element}>
                <section className="bloc-1">
                    <div className="logo-container">
                        <Logo />
                    </div>
                    <nav className="navigation">
                        <HeaderSearch icon={PiMagnifyingGlass}>
                            Rechercher un client
                        </HeaderSearch>
                        <DefaultMenu />
                        <MainMenu />
                        <ClientsMenu />
                        {user.clients.length > 0 && <CustomerClients/>}
                        {isAdmin &&
                            <AdminMenu />
                        }
                        <SecondaryMenu />
                    </nav>
                </section>
            </StyledAside>
        </>
    );
}


export default Header;