import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../states/Loading';
import NotFound from '../../states/NotFound';
import { ROUTES } from '../../routes/routes';

function ProtectedRoutes({ adminOnly = false, reltimOnly = false, customerOnly = false }) {
    const { isLoggedIn, profileCompleted, isAdmin, isReltim, isCustomer } = useUser();

    // Rediriger vers la page de connexion si l'utilisateur n'est pas connecté
    if (!isLoggedIn) {
        return <Navigate to={ROUTES.LOGIN} replace />;
    }

    // Afficher un écran de chargement si le profil est en cours de vérification
    if (profileCompleted === null || isLoggedIn === null || isLoggedIn === undefined) {
        return <Loading />;
    }

    // Rediriger vers la page de complétion du profil si le profil n'est pas complété
    if (profileCompleted === false) {
        return <Navigate to={ROUTES.COMPLETE_PROFILE} replace />;
    }

    // Vérifier si la route est réservée aux admins Reltim
    if (adminOnly && !isAdmin) {
        return <NotFound />;
    }

    // Vérifier si la route est réservée à Reltim
    if (reltimOnly && !isReltim) {
        return <NotFound />;
    }

    // Vérifier si la route est réservée aux clients
    if (customerOnly && !isCustomer) {
        return <NotFound />;
    }

    // Rendre les routes enfants si toutes les conditions sont remplies
    return <Outlet />;
}

export default ProtectedRoutes;
