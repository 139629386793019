import React, {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/ui/aside-section/Header';
import Main from '../components/box/main/Main';
import TopBar from '../components/ui/topbar/TopBar.js';
import '../assets/style/style.css';
import BottomBar from '../components/ui/bottom-bar/BottomBar.js';
import { useUser } from '../contexts/UserContext.js';
import CustomerHeader from '../components/ui/aside-section/CustomerHeader.js';

const DefaultLayout = ({ bottombar, bottombarvariant }) => {
    const {isAdmin, isReltim, isCustomer} = useUser();
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        const isResponsive = window.matchMedia("(max-width: 1000px)").matches;
        if (isResponsive) {
            setSidebarOpen(false);
        }
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
      <div className="App">
          {isCustomer ? <CustomerHeader isopen={sidebarOpen} setisOpen={setSidebarOpen} /> : <Header isopen={sidebarOpen} setisOpen={setSidebarOpen} />}
          <Main variant={sidebarOpen ? 'wsidebar' : 'wnotsidebar'}>
              <TopBar toggleSidebar={toggleSidebar} />
              <Outlet /> 
              {bottombar && <BottomBar bottombarvariant={bottombarvariant} />}
          </Main>
      </div>
    );
}

export default DefaultLayout;
